<template>
  <div class="bg-loading"></div>
</template>

<style lang="scss">
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
@import "@astrocentro-webapp/ui/assets/stylesheets/vendor.scss";

.bg-loading {
  background-color: rgba($gray-900, 0.2);
  margin-left: $base-size * -1.5;
  margin-right: $base-size * -1.5;
  height: $base-size * 55;
  margin-bottom: $base-size * 2;
}

@include media-breakpoint-up(md) {
  .bg-loading {
    height: $base-size * 70;
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
