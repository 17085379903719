export const stars = {
  1: ["star-half", "star", "star", "star", "star"],
  2: ["star-fill", "star", "star", "star", "star"],
  3: ["star-fill", "star-half", "star", "star", "star"],
  4: ["star-fill", "star-fill", "star", "star", "star"],
  5: ["star-fill", "star-fill", "star-half", "star", "star"],
  6: ["star-fill", "star-fill", "star-fill", "star", "star"],
  7: ["star-fill", "star-fill", "star-fill", "star-half", "star"],
  8: ["star-fill", "star-fill", "star-fill", "star-fill", "star"],
  9: ["star-fill", "star-fill", "star-fill", "star-fill", "star-half"],
  10: ["star-fill", "star-fill", "star-fill", "star-fill", "star-fill"],
};
