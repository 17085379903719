<script setup>
import { reactive, onMounted, computed, ref, watch } from "vue";
import { VLogo } from "@astrocentro-webapp/ui-components/modules/commons/components/VLogo";
import { VIcon } from "@astrocentro-webapp/ui-components/modules/commons/components/VIcon";
import VAvatar from "@astrocentro-webapp/ui-components/modules/commons/components/VAvatar/VAvatar.vue";
import { stars } from "../../../../../../ui/src/modules/sellers/components/SellerPresentationCard/data";
import { SellerPresentationGhostBottom } from "./index";
import { SellerPresentationGhostTop } from "./index";

const astrocentroUrl = import.meta.env.VITE_ASTROCENTRO_URL;
const appAstrocentroUrl = import.meta.env.VITE_APP_ASTROCENTRO_URL;

let state = reactive({
  isLoading: false,
  currentSeller: 0,
  showNextButton: true,
  showPrevButton: false,
  consultationTypeLabel: "Chat",
  consultationTypeValue: "chat",
  consultationButton: "Ligar agora",
  isDescriptionOpen: false,
  consultationPrice: "",
});

const props = defineProps({
  selectedSellers: {
    type: Object,
    default: {},
  },
});

const rating = computed(() => {
  const rawRating =
    Math.round(props.selectedSellers?.sellers[state.currentSeller]?.rating) /
    10;
  return rawRating <= 9.9 ? Math.floor(rawRating) : Math.round(rawRating);
});

const urlConsultation = ref("");

const nextSeller = () => {
  if (state.currentSeller < props.selectedSellers?.sellers.length - 1) {
    state.currentSeller++;
  }
  state.showNextButton = !(
    state.currentSeller >=
    props.selectedSellers?.sellers.length - 1
  );

  state.showPrevButton = !(state.currentSeller === 0);
  getChatPrice();
};

const prevSeller = (e) => {
  e.preventDefault();
  if (state.currentSeller !== 0) {
    state.currentSeller = state.currentSeller - 1;
  }
  state.showPrevButton = !(state.currentSeller === 0);

  state.showNextButton = !(
    state.currentSeller >=
    props.selectedSellers?.sellers.length - 1
  );
  getChatPrice();
};

const setUrlConsultation = () => {
  if (props.selectedSellers?.sellers.length === 1) state.showNextButton = false;

  urlConsultation.value = `${astrocentroUrl}/c/${state.consultationTypeValue}/${
    props.selectedSellers?.sellers[state.currentSeller]?.externalId
  }`;
  if (props.selectedSellers?.pendingPackage)
    urlConsultation.value += `?tracker_id=${props.selectedSellers?.pendingPackage[0]}`;
};

const setIsLoadingTransition = () => {
  state.isLoading = true;
  setUrlConsultation();
  setTimeout(() => {
    state.isLoading = false;
  }, 800);
};

watch(
  () => state.currentSeller,
  (newValue) => {
    setIsLoadingTransition();
  }
);

onMounted(() => {
  setIsLoadingTransition();
  getChatPrice();
});

const getChatPrice = () => {
  let sellerServices =
    props.selectedSellers?.sellers[state.currentSeller]?.services.services;
  let chatService = sellerServices.find(
    (service) => service.service === "chat-consultation"
  );

  state.consultationPrice = `${chatService.price["formatted"]}/min`;
};

const getLabelPrice = () => {
  if (props.selectedSellers?.pendingPackage) {
    let pendingPackage = props.selectedSellers?.pendingPackage[1];
    let pendingPrice = pendingPackage.split("-")[3];
    pendingPrice = pendingPrice.replace(".", ",");
    let pendingMinutes = pendingPackage.split("-")[1];

    return `R$ ${pendingPrice} para ${pendingMinutes} min`;
  } else {
    return state.consultationPrice;
  }
};
</script>

<template>
  <div class="row justify-content-center text-center">
    <div class="col-12 col-sm-5">
      <VLogo class="my-5" variant="sm" />
      <div v-if="state.isLoading" class="mt-5 mt-md-3">
        <SellerPresentationGhostTop />
        <SellerPresentationGhostBottom />
      </div>
      <div v-else-if="props.selectedSellers?.sellers.length">
        <div class="mb-4">
          <VAvatar
            :src="
              props.selectedSellers?.sellers[state.currentSeller]
                ?.institutionalPicture
            "
            variant="lg"
          />
        </div>
        <h1 class="mb-1">
          {{ props.selectedSellers?.sellers[state.currentSeller]?.name }}
        </h1>
        <div
          class="d-flex justify-content-center align-items-center gap-2 mb-5"
          v-if="rating"
        >
          <VIcon
            v-for="(star, index) of stars[rating]"
            color="secondary"
            :key="index"
            :icon="star"
            :width="18"
            :height="18"
          />
          <span class="text-gray-400"
            >({{
              props.selectedSellers?.sellers[state.currentSeller]
                ?.numberOfTransactions
            }}
            consultas)</span
          >
        </div>
        <div class="d-flex justify-content-center mb-3">
          <div
            class="badge rounded-pill bg-light text-dark fs-6 d-flex align-items-center"
          >
            <VIcon icon="star-fill" :width="18" :height="18" class="me-2" />
            Sua melhor escolha
          </div>
        </div>
        <div
          :class="`mb-2 description ${
            state.isDescriptionOpen
              ? 'description-open overflow-auto'
              : 'overflow-hidden'
          }`"
          v-html="
            props.selectedSellers?.sellers[state.currentSeller]
              ?.profileDescription
          "
        ></div>
        <button
          class="btn btn-outline"
          @click="state.isDescriptionOpen = !state.isDescriptionOpen"
        >
          Ler mais
        </button>
        <hr />
        <div class="mb-6">
          <div class="d-flex align-items-center justify-content-center mb-3">
            <VIcon
              icon="chat-square"
              :width="24"
              :height="24"
              class="text-gold me-2"
            />
            <small class="text-gold fw-bold">Chat</small>
          </div>
          <div v-if="props.selectedSellers?.pendingPackage">
            <h4 class="font-bolder">{{ getLabelPrice() }}</h4>
            <span class="d-block fs-6 mt-2"
              >em seguida
              <span class="font-bolder">R$ 4,49/min</span></span
            >
          </div>
          <div v-else>
            <h4 class="font-bolder">{{ getLabelPrice() }}</h4>
          </div>
        </div>
        <div class="row justify-content-center mb-3">
          <div class="col-12 col-sm-10">
            <div class="d-grid gap-3">
              <a
                @click.prevent="
                  (event) =>
                    $emit('choose-seller', {
                      event,
                      seller:
                        props.selectedSellers?.sellers[state.currentSeller],
                    })
                "
                :href="urlConsultation"
                class="btn btn-primary"
              >
                Iniciar o chat
              </a>
              <button
                v-if="state.showNextButton"
                @click="nextSeller"
                class="btn btn-outline-primary"
              >
                Veja mais opções de especialista
              </button>
              <a
                v-if="state.showPrevButton"
                href="#"
                @click="prevSeller"
                class="text-blue mt-5"
                target="_blank"
                >Ver o especialista anterior</a
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row justify-content-center my-5">
        <p>Não há especialistas disponíveis no momento</p>
        <a class="btn btn-outline-primary mt-2" :href="`${appAstrocentroUrl}/caminhos-do-destino`">voltar</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";

.font-bolder {
  font-weight: $font-weight-bolder;
}
.description {
  height: $base-size * 15;
}
.description-open {
  height: $base-size * 37;
}
</style>
